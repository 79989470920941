
.EntityTaxonomy-added {
	font-weight: bold;
	margin: 10px 0px 2px 20px;
	color: #009fe3;
}

.EntityTaxonomy-removed {
	font-weight: bold;
	margin: 10px 0px 2px 20px;
	color: #e40613;
}