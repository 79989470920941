
.TreeTaxonomy {
	width: 100%;
	position: relative;
	border: lightgrey 2px solid;
    border-radius: 0.4rem;
    background-color: white;
}

.TreeTaxonomy-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	background: repeating-linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 10px,
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0) 20px
    );
}

.TreeTaxonomy-button {
	position: absolute;
	top: 2px;
	right: 2px;
}

.TreeTaxonomy__root > g > circle, .TreeTaxonomy__branch > g > circle {
	width: 20px !important;
	height: 20px !important;
	stroke: lightgrey !important;
	fill: lightgrey !important;
}

.rd3t-link {
	stroke: lightgrey !important;
	stroke-width: 2;
}

.rd3t-tree-container {
	height: 100% !important;
    border: none !important;
    background-color: white;
    margin: 0px 0px 0px 0px !important;
}

.TreeTaxonomy-node-text {
	font-weight: bold;
}