
.EntityLogo-dragdrop {
	padding: 20px;
	width: 100%;
	height: 100px;
    border: 7px dashed lightgrey;
    border-radius: 8px;
    display: table;
}

.EntityLogo-dragdrop-textContent {
	display: table-cell;
	height: 100%;
	width: 100%;
	text-align: center;
	color: grey;
	vertical-align: middle;
}

.EntityLogo-logo-change {
	max-height: 200px;
	max-width: 200px;
	width: 100%;
}

.EntityLogo-center {
	text-align: center;
}