
.EditMetadata-top-menu {
    padding: 0px 0px 20px 0px;
    text-align: center;
}

.EditMetadata-top-menu button {
        bottom: unset !important;
        right: unset !important;
    }

@media (min-width: 768px) {
    .EditMetadata-top-menu {
        padding: 5px 20px 0px 0px !important;
        text-align: right !important;
    }
}
