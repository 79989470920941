
.ArticleHorizontal {
    margin: 5px 0px !important;
    padding-bottom: 50px;
    overflow: hidden;
    line-height: 1 !important;
}

.ArticleHorizontal-link {
    color: inherit;
}

@media (min-width: 500px) {
    .ArticleHorizontal .card-horizontal {
        height: 100%;
        display: flex;
        flex: 1 1 auto;
    }
}

@media (min-width: 0px) {
    .ArticleHorizontal .img-square-wrapper {
        width: 100%;
        height: 130px;
    }
}

@media (min-width: 500px) {
    .ArticleHorizontal .img-square-wrapper {
        object-fit: cover;
        display: flex;
        margin: auto;
        flex: 0 0 220px;
        padding: 12px 15px !important;
    }
}

.ArticleHorizontal .img-square-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: unset !important;
}

.ArticleHorizontal .card-blue-boxes {
    position: absolute;
    text-transform: uppercase;
    bottom: 0px;
    left: 15px;
}

.ArticleHorizontal .card-blue-box {
    display: inline-block;
    background: #009fe3;
    text-align: center;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    margin-left: 10px;
    color: #fff;
    text-transform: uppercase;
}

.ArticleHorizontal .card-body {
    padding: .9rem 1rem !important;
}

@media (min-width: 0px) {
    .ArticleHorizontal .card-body button {
        display: none;
    }

    .ArticleHorizontal .card-body h5 {

    }
}

@media (min-width: 500px) {
    .ArticleHorizontal .card-body button {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
}

.ArticleHorizontal .card-body h5 {
    font-weight: bold !important;
    margin-bottom: 5px !important;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 0px) {
    .ArticleHorizontal .card-body button {
        display: none;
    }
}

@media (min-width: 500px) {
    .ArticleHorizontal .card-body button {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
}

.ArticleHorizontal .card-tags {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ArticleHorizontal .card-text {
    padding: 10px 1rem 5px 1rem;
    text-align: justify;
}

.ArticleHorizontal .ArticleStatus {
    position: absolute;
    top: 10px;
    right: 15px;
}