
.PageLogoGenerator-canvas-wrapper {
	text-align: center;
	margin: auto;
}

.PageLogoGenerator-canvas {
	margin: 5px 5px 5px 5px;
	background-image: linear-gradient(45deg, #ddd 25%, transparent 0),
		linear-gradient(45deg, transparent 75% ,#ddd 0),
		linear-gradient(45deg, transparent 75%,#ddd 0),
		linear-gradient(45deg, #ddd 25%,transparent 0);
	background-size: 10px 10px;
	background-position: 0 0,0 0,-5px -5px,5px 5px;
}

.PageLogoGenerator-canvas-wrapper canvas {
	max-width: 100%;
}

.PageLogoGenerator-download-link {
	margin: 0px 5px;
}