
.DialogConfirmation-wrapper {
    padding-top: 20px;
    padding-bottom: 60px;
}

.DialogConfirmation-wrapper > b {
    white-space: normal;
    text-overflow: clip;
}

.DialogConfirmation-wrapper button {
    display: inline !important;
    width: auto !important;
}