
.DialogArticleEditor-content {
	z-index: 100;
	height: 100% !important;
	width: 100% !important;
    line-height: 1.5 !important;
}

.DialogArticleEditor-content p {
    text-overflow: initial !important;
    white-space: initial !important;
    overflow: auto !important;
}

.DialogArticleEditor-content button {
	display: inline !important;
	position: relative !important;
}

.DialogArticleEditor-item-remove-button {
	position: absolute;
	top: 1px;
	right: 3px;
    z-index: 150;
}

.DialogArticleEditor-item-remove-button:hover {
    color: #ffa8b0;
}

.DialogArticleEditor-item {
    position: absolute;
	border: lightgrey 1px solid;
    background: repeating-linear-gradient(
      135deg,
      lightgrey,
      lightgrey 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 4px
    );
}

.DialogArticleEditor-item .col-md-12 {
    margin: 0;
}

.DialogArticleEditor-item .FormLine .row div .FormLine-label {
	padding: 2px 10px !important;
	cursor: pointer;
}

.DialogArticleEditor-item .FormLine .row div textarea {
	height: calc(100% - 11px);
	resize: none;
}

.DialogArticleEditor-block-buttons {
    margin: 15px 0px;
    text-align: center;
}

.DialogArticleEditor-layout {
    margin-left: 15px;
}

.DialogArticleEditor-item-new {
    border: #8fddff 1px solid;
    background: repeating-linear-gradient(
        135deg,
        #8fddff,
        #8fddff 1px,
        rgba(0, 0, 0, 0) 1px,
        rgba(0, 0, 0, 0) 4px
    );
}

.DialogArticleEditor-item-modified {
    border: #bcebff 1px solid !important;
    background: repeating-linear-gradient(
        135deg,
        #bcebff,
        #bcebff 1px,
        rgba(0, 0, 0, 0) 1px,
        rgba(0, 0, 0, 0) 4px
    ) !important;
}

.DialogArticleEditor-image-wrapper {
    height: 100%;
}

.medium-editor-element blockquote {
    margin: 0px 10px;
    font-style: italic;
}

.DialogArticleEditor-item .popup-content {
    width: 100% !important;
}

.DialogArticleEditor-top-bar {
    margin-bottom: 15px;
}

.DialogArticleEditor-top-bar button {
    bottom: 5px;
    right: 0px;
}

@media (min-width: 768px) {
    .DialogArticleEditor-menu {
        z-index: 105;
        position: fixed;
        width: inherit;
        max-width: inherit;
        height: calc(100vh - 100px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .DialogArticleEditor-menu-wrapper {
        padding: 0 !important;
    }
}

.DialogArticleEditor-menu {
    background-color: black;
    padding: 20px 10px;
}

.DialogArticleEditor-menu h3 {
    color: white;
}

.DialogArticleEditor-menu button {
    width: 100%;
    text-align: left;
    bottom: 0 !important;
    right: 0 !important;
}

.DialogArticleEditor-menu .link-button {
    color: grey !important;
    background-color: black !important;
    opacity: 1;
    border-radius: unset;
}

.DialogArticleEditor-menu .selected-link-button {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: white !important;
    cursor: default;
}

.DialogArticleEditor-menu .ArticleStatus {
    position: relative !important;
    top: unset;
    right: unset;
    margin: 5px auto 15px auto;
}

@media (min-width: 768px) {
    .DialogArticleEditor-body {
        padding: 0px 35px;
    }
}

.DialogArticleEditor-menu button i {
    width: 20px;
    text-align: center;
}

.DialogArticleEditor-content .DialogHint-icon {
    margin: 0px 10px 0px 10px;
}

.DialogArticleEditor-top-menu {
    padding: 0px 0px 20px 0px;
    text-align: center;
}

.DialogArticleEditor-top-menu button {
        bottom: unset !important;
        right: unset !important;
    }

@media (min-width: 768px) {
    .DialogArticleEditor-body {
        padding: 0px 35px;
    }

    .DialogArticleEditor-top-menu {
        padding: 10px 40px 0px 0px !important;
        text-align: right !important;
    }
}
